/*
 *   全局自动注册组件
 * */

import Vue from "vue";
import Icon from "./icon/index.vue";

const install = (vue: typeof Vue) => {
    vue.component("BcIcon", Icon);
};

export default install;
