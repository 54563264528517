/*
*   请求接口封装
* */
import axios, { ParamMixHospital } from "@/api/axios";
import apiList from "@/api/apiList";
import { AxiosResponse } from "axios";
import { Obtain, CBindPatient, BindUser, BloodSugar, BloodPressure, Temperature, Weight, TakeMedicine, CPatientInfo, PatientMedicalHis, Disease, Ward, Dept, Doctor, Survey, Medicine, CheckOut, CImage, Article, Hospital } from "@/@type/index"
import { CFollowInfo, CFollowPlan, CFollowProject, CFollowTask, followRecord, followTemplate, DoctorCountData, IBcVisitBeforeRemind } from "@/@type/follow";
import { fileFormData } from "@/utils/common";
import { Drug } from "@/@type/cache";

// // 请求用户列表接口
// export const getUserList = () => {s
//     return axios.get(apiList.USER_LIST)
// };


// 用户登录接口
export const login = (username: string, password: string, hospitalId: number, isPatient: boolean = true) => {
    return axios.post<Obtain<any>>(apiList.USER_LOGIN, { username, password, isPatient, hospitalId });
};

/** 登录（使用医院id换取token） */
export const patientLogin = (hospitalId: number) => {
    return axios.get<Obtain<any>>(apiList.USER_LOGIN_PATIENT, { params: { hospitalId } });
};

export const getPatientReadList = (openId: string, patientId?: string) => {
    const data = ParamMixHospital({ openId, patientId });
    return axios.get<Obtain<CBindPatient[]>>(apiList.PATIENT_CARD_LIST, { params: data });
}

export const getHisPatient = (limit: number, page: number, patientId: string) => {
    const data = ParamMixHospital({ limit, page, patientId });
    return axios.get<Obtain<BindUser[]>>(apiList.PATIENT_DETAIL, { params: data });
}



// 绑定就诊卡号
export const bindPatient = (openId: string, patientId: string) => {
    const data = ParamMixHospital({ openId, patientId });
    return axios.post<Obtain<any>>(apiList.PATIENT_CARD_LIST, data);
}

/** 获取患者列表（随访系统） */
export const getPatientList = (patientId: string) => {
    const data = ParamMixHospital({ patientId });
    return axios.get<Obtain<CPatientInfo[]>>(apiList.PATIENT_LIST, { params: data });
}

// 获取患者血糖
export const getPatientBloodSugar = (page: number, limit: number, patientId: string) => {
    const data = ParamMixHospital({ page, limit, patientId });
    return axios.get<Obtain<BloodSugar[]>>(apiList.BLOOD_SUGAR, { params: data });
}

// 新增患者血糖
export const addPatuentBloodSugar = (bloodSugar: BloodSugar) => {
    const data = ParamMixHospital(bloodSugar);
    return axios.post<Obtain<undefined>>(apiList.BLOOD_SUGAR, data);
}

// 获取患者血压记录
export const getPatientBloodPressure = (page: number, limit: number, patientId: string) => {
    const data = ParamMixHospital({ page, limit, patientId });
    return axios.get<Obtain<BloodPressure[]>>(apiList.BLOOD_PRESSURE, { params: data });
}

// 新增患者血压记录
export const addPatientBloodPressure = (bloodPressure: BloodPressure) => {
    const data = ParamMixHospital(bloodPressure);
    return axios.post<Obtain<undefined>>(apiList.BLOOD_PRESSURE, data);
}

// 获取患者体温记录
export const getPatientTemperature = (page: number, limit: number, patientId: string) => {
    const data = ParamMixHospital({ page, limit, patientId });
    return axios.get<Obtain<Temperature[]>>(apiList.TEMPERATURE, { params: data });
}

// 添加患者体温记录
export const addPatientTemperature = (temperature: Temperature) => {
    const data = ParamMixHospital(temperature);
    return axios.post<Obtain<undefined>>(apiList.TEMPERATURE, data);
}

// 获取患者体重记录
export const getPatientWeight = (page: number, limit: number, patientId: string) => {
    const data = ParamMixHospital({ page, limit, patientId });
    return axios.get<Obtain<Weight[]>>(apiList.WEIGHT, { params: data });
}

// 新增患者体重记录
export const addPatientWeight = (weight: Weight) => {
    const data = ParamMixHospital(weight);
    return axios.post<Obtain<undefined>>(apiList.WEIGHT, data);
}

// 获取患者服药记录
export const getPatientTakeMedicine = (page: number, limit: number, patientId: string) => {
    const data = ParamMixHospital({ page, limit, patientId });
    return axios.get<Obtain<TakeMedicine[]>>(apiList.TAKE_MEDICINE, { params: data });
}

// 添加患者服药记录
export const addPatientTakeMedicine = (takeMedicine: TakeMedicine) => {
    const data = ParamMixHospital(takeMedicine);
    return axios.post<Obtain<undefined>>(apiList.TAKE_MEDICINE, data);
}

// 获取患者随访记录
export const getFollowInfo = (page: number, limit: number, patientId: string) => {
    const data = ParamMixHospital({ page, limit, patientId });
    return axios.get<Obtain<CFollowInfo[]>>(apiList.FOLLOW_INFO, { params: data });
}

// 获取患者随访计划列表
export const getFollowPlanList = (page: number, limit: number, patientId?: string | number, fuId?: number | string, status?: string) => {
    const data = ParamMixHospital({ page, limit, patientId, fuId, status });
    return axios.get<Obtain<CFollowPlan[]>>(apiList.FOLLOW_PLAN, { params: data });
}

//根据随访计划id查询患者随访计划
export const getFollowPlan = (page: number, limit: number, patientId: string | number, id: number | string, fuId?: number | string) => {
    const data = ParamMixHospital({ page, limit, patientId, id, });
    return axios.get<Obtain<CFollowPlan[]>>(apiList.FOLLOW_PLAN, { params: data });
}

// 修改患者随访计划
export const updateFollowPlan = (id: string | number, status: string) => {
    const data = ParamMixHospital({ status });
    return axios.put<Obtain<undefined>>(apiList.FOLLOW_PLAN + '/' + id, data);
}

// 修改随访任务
export const updateFollowTask = (id: string | number, status: string) => {
    const data = ParamMixHospital({ status });
    return axios.put<Obtain<undefined>>(apiList.PATIENT_TASK + '/' + id, data);
}

// 获取随访任务
export const getFollowTask = (id: string | number) => {
    const data = ParamMixHospital({ id });
    return axios.get<Obtain<CFollowTask[]>>(apiList.PATIENT_TASK, { params: data });
}

// 获取患者入院诊断信息（病史信息）
export const getPatientMedicalHis = (page: number, limit: number, patientId: string) => {
    const data = ParamMixHospital({ page, limit, patientId });
    return axios.get<Obtain<PatientMedicalHis[]>>(apiList.PATIENT_DIAG, { params: data });
}

// 新增随访项目明细
export const updateFollowProjectItem = (id: string | number, status: string, itemName: string, creator: string) => {
    const data = ParamMixHospital({ status, itemName, creator, date: new Date().format('yyyy-MM-dd') });
    return axios.put<Obtain<undefined>>(apiList.PROJECT_ITEM + '/' + id, data);
}

// 检查单检验单图片上传
export const uploadCheckImage = (file: File, fuProjectId: string | number, fuProjectItemId: string | number) => {
    const data = ParamMixHospital({ img: file, fuProjectId, fuProjectItemId });
    return axios.post<Obtain<undefined>>(apiList.PROJECT_ITEM_IMAGE, fileFormData(data));
}

// 获取检验单详情
export const getCheckOutInfo = (fuProjectId: string | number) => {
    const data = ParamMixHospital({ fuProjectId });
    return axios.get<Obtain<CheckOut[]>>(apiList.CHECK_OUT_INFO, { params: data });
}

// 获取用药提醒详情
export const getMedicineInfo = (fuProjectId: string | number, fupId?: string) => {
    const data = ParamMixHospital({ fuProjectId, fupId });
    return axios.get<Obtain<Medicine[]>>(apiList.MEDICINE_MIND, { params: data });
}

export const getCheckImage = (fuProjectId: string | number) => {
    const data = ParamMixHospital({ fuProjectId });
    return axios.get<Obtain<CImage[]>>(apiList.PROJECT_ITEM_IMAGE, { params: data });
}

// 获取宣教文章列表
export const getArticleList = (page: number, limit: number, id: string) => {
    const data = ParamMixHospital({ page, limit, id });
    return axios.get<Obtain<Article[]>>(apiList.PATIENT_EDU_ARTICLE, { params: data });
}

// 获取患者随访项目明细
export const getFollowProjectItem = (page: number, limit: number, id: string) => {
    const data = ParamMixHospital({ page, limit, id });
    return axios.get<Obtain<CFollowProject[]>>(apiList.PROJECT_ITEM, { params: data });
}

// 获取调查问卷模板
export const getSurveyTemp = (id: string) => {
    const data = ParamMixHospital({ id });
    return axios.get<Obtain<Survey[]>>(apiList.INVESTIGATION_TYPE, { params: data });
}

// 获取患者所有的调查问卷列表
export const getPatientSurveyList = (page: number, limit: number, tempId: string, itemId: string) => {
    const data = ParamMixHospital({ page, limit, tempId, itemId });
    return axios.get<Obtain<Survey[]>>(apiList.SURVEY_DETAIL, { params: data });
}

// 提交调查问卷（新增问卷结果）
export const addSurveyResult = (fupId: string, result: string, name: string, title: string, isDone: boolean) => {
    const data = ParamMixHospital({ fupId, result, name, title, isDone });
    return axios.post<Obtain<undefined>>(apiList.INVESTIGATION_DETAIL, data);
}

// 获取患者随访记录
export const getFollowRecord = (page: number, limit: number, fupsId?: string | number, id?: string | number) => {
    const data = ParamMixHospital({ page, limit, fupsId, id });
    return axios.get<Obtain<followRecord[]>>(apiList.FOLLOW_RECORD, { params: data });
}

// 获取医生列表
export const getDoctorList = (page: number, limit: number, doctorCode?: string) => {
    const data = ParamMixHospital({ page, limit, doctorCode });
    return axios.get<Obtain<Doctor[]>>(apiList.DOCTOR_INFO_LIST, { params: data });
}

// // 获取科室列表
// export const getDeptList = (page: number, limit: number) => {
//     return axios.get<Obtain<Dept[]>>(apiList.DEPTH_LIST, { params: { page, limit } });
// }

export const getDeptList = (page: number, limit: number) => {
    const data = ParamMixHospital({ page, limit });
    return axios.get<Obtain<Dept[]>>(apiList.DEPT, { params: data });
}

// 获取病种字典信息
export const getDiseaseList = (deptCode?: string) => {
    const data = ParamMixHospital({ deptCode });
    return axios.get<Obtain<Disease[]>>(apiList.FOLLOW_DISEASE, { params: data });
}

//  获取患者-病种绑定信息
export const getPatientDisease = (patientId?: string) => {
    const data = ParamMixHospital({ patientId });
    return axios.get<Obtain<Disease[]>>(apiList.PATIENT_DISEASE, { params: data });
}

// 患者报到接口（新）
export const reportReceive = (patientId: string, phone: string, deptCode: string, deptName: string) => {
    const data = ParamMixHospital({ patientId, phone, deptCode, deptName });
    return axios.post<Obtain<CFollowInfo>>(apiList.REPORT_RECEIVE, data);
}

//  获取随访计划模板列表
export const getFollowTemplate = (followUpDiseaseDictId?: string) => {
    const data = ParamMixHospital({ followUpDiseaseDictId });
    return axios.get<Obtain<followTemplate[]>>(apiList.PATIENT_FOLLOW_TEMPLATE, { params: data });
}

// 新增患者随访计划
export const addFollowPlan = (params: { list: CFollowPlan[] }) => {
    const data = ParamMixHospital(params);
    return axios.post<Obtain<undefined>>(apiList.FOLLOW_PLAN, data);
}

//  获取医生各项统计数据
export const getDoctorCount = (doctorCode?: string) => {
    const data = ParamMixHospital({ doctorCode });
    return axios.get<Obtain<DoctorCountData>>(apiList.DOCTOR_COUNT, { params: data });
}

//  获取患者随访项目列表
export const getFollowProject = (page: number, limit: number, futId: number | string) => {
    const data = ParamMixHospital({ page, limit, futId });
    return axios.get<Obtain<CFollowProject[]>>(apiList.PROJECT, { params: data });
}

//  获取病区列表
export const getWardList = (page: number, limit: number) => {
    const data = ParamMixHospital({ page, limit });
    return axios.get<Obtain<Ward[]>>(apiList.WARD_LIST, { params: data });
}

// 新增投诉建议
export const addSuggest = (params: any) => {
    const data = ParamMixHospital(params);
    return axios.post<Obtain<undefined>>(apiList.COMPLAINTS_SUGGEST, data);
}

// 患者头像上传 patientId为患者的id,不是patientId
export const patientPhotoUpload = (img: File, patientInfoId: number) => {
    const data = ParamMixHospital({ img, patientInfoId });
    return axios.post<Obtain<undefined>>(apiList.PATIENT_PROFILE_PHOTO, fileFormData(data))
};

// 新增用户接口
export const addUser = (data: unknown) => {
    return axios.post(apiList.USER_UNCHECKING, data);
};

// 查询短信模板(showSmsContent为转换后的展示数据)
export const getSmsTemplate = (page: number, limit: number,) => {
    const data = ParamMixHospital({ page, limit });
    return axios.get<Obtain<any>>(apiList.SMS_TEMP, { params: data })
};

// 获取某个患者所有短信参数值
export const getPatientParamValue = (params?: unknown) => {
    const data = ParamMixHospital(params);
    return axios.get(apiList.PATIENT_PARAM_VALUE, { params: data })
};

// 无token添加用户随访记录
export const addPatientCheck = (patientInfo: any, endoscopeInfo: { type: string, isAnesthesia?: boolean }, doctorInfo: { doctorCode: string, doctorName: string }, hospitalId: number) => {
    const data = ParamMixHospital({ patientInfo, endoscopeInfo, doctorInfo, hospitalId });
    return axios.post<Obtain<any>>(apiList.PATIENT_CHECK, data)
};

// 查询角色绑定的用户列表
export const getRoleUlserList = (userName?: string, userId?: string, roleName?: string, roleId?: string) => {
    const data = ParamMixHospital({ userName, userId, roleName, roleId });
    return axios.get<Obtain<any>>(apiList.ROLE_USER, { params: data })
};

// 获取药品字典
export const getDrugDict = () => {
    const data = ParamMixHospital();
    return axios.get<Obtain<Drug[]>>(apiList.DRUG_DICT, { params: data });
};

// 获取医院列表
export const getHospital = () => {
    return axios.get<Obtain<Hospital[]>>(apiList.HOSPITAL_DICT);
};

// 退出登录
export const logout = () => {
    return axios.post<Obtain<void>>(apiList.LOGOUT)
}

// 获取患者姓名
export const getPatientName = (patientId: string, hospitalId: number) => {
    const data = ParamMixHospital({ patientId, hospitalId });
    return axios.get<Obtain<{ patientId: string, patientName: string }>>(apiList.PATIENT_UNSAFE, { params: data });
}

// 检查是否有随访计划
export const getFollowPlanCheck = (data: { patientId: string, hospitalId: number, deptCode: string, fuTempId: number }) => {
    data = ParamMixHospital(data);
    return axios.get<Obtain<boolean>>(apiList.FOLLOW_PLAN_CHECK_UNSAFE, { params: data });
}

/** 检查患者是否生成随访信息（术前1/术后2） */
export const getFollowStatus = (data: { patientId: string, hospitalId: number | string }) => {
    return axios.get<Obtain<any>>(apiList.FOLLOW_STATUS, { params: { ...data, hospitalId: Number(data.hospitalId) } });
}

/** 获取患者术前随访信息 */
export const getPatientPedtRecord = (data: { patientId: string, registerId?: number }) => {
    data = ParamMixHospital(data);
    return axios.get<Obtain<any>>(apiList.PATIENT_PEDT_RECORD, { params: data });
}

/** 修改患者术前随访信息详细 */
export const updatePatientPedtRecordDetail = (data: IBcVisitBeforeRemind) => {
    return axios.put<Obtain<any>>(apiList.PATIENT_PEDT_RECORD_DETAIL + '/' + data.id, data);
}

/** 上传术前随访服药图片 */
export const uploadPedtImg = (data: { hospitalId: number, pedtReocrdDrugId: number, img: File }) => {
    return axios.post<Obtain<any>>(apiList.UPLOAD_PEDT_IMG, fileFormData(data));
}

