import Vue from "vue";
import Vuex from "vuex";
// import User from "@/store/user"
import { Commit } from 'vuex'
import mqtt from "@/mqtt"
import { CPatientInfo } from "@/@type/index";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userInfo: {} as CPatientInfo,
        token: "",
        username: "",
        isRefresh: true,
        excludePage: [] as string[],
        followParamsDicts: [], // 内镜-随访对接参数字典（后期补充，格式为：{ id:1, type: 'key', value: 'patientId' }）
        timeTaskList: [], // 定时任务列表
        hospitalList: [], // 医院列表字典表
        followStatus: '0', // 当前患者随访状态（0： 无， 1：术前， 2：术后）
    },
    getters: {
        getUserInfo: (state) => state.userInfo,
        userInfo: (state) => state.userInfo?.patientId ? state.userInfo : JSON.parse(localStorage.getItem('userInfo') || '{}'),
        getToken: (state) => state.token || JSON.parse(localStorage.getItem('user') || '{}').token || '',
        getUsername: (state) => state.username,
        getIsRefresh: (state) => state.isRefresh,
        getExcludePage: (state) => state.excludePage,
        followParamsDicts: (state) => state.followParamsDicts,
        timeTaskList: (state) => state.timeTaskList,
        hospitalList: (state) => state.hospitalList,
        followStatus: (state) => state.followStatus,
    },
    mutations: {
        setToken: (state: any, payload: string) => {
            if (payload && !mqtt.clientId) mqtt.connetServer() // 登录成功后如未连接mqtt，需要进行连接
            const user = JSON.parse(localStorage.getItem('user') || '{}')
            state.user = { ...user, token: payload };
            state.token = payload
            localStorage.setItem('user', JSON.stringify(state.user))
        },
        setUserInfo: (state: any, userInfo: CPatientInfo) => {
            state.userInfo = userInfo;
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
        },
        setUsername: (state: any, username: string) => {
            state.username = username;
        },
        switchIsRefresh: (state) => {
            state.isRefresh = !state.isRefresh;
        },
        setExcludePage: (state, push: string) => {
            state.excludePage.push(push);
        },
        removalExcludePage: (state) => {
            state.excludePage = [];
        },
        setTimeTask: (state, list) => {
            state.timeTaskList = list
        },
        setHospitalList: (state, list) => {
            state.hospitalList = list
        },
        setFollowStatus: (state, status) => {
            state.followStatus = status
        }
    },
    actions: {
        token({ commit }: { commit: Commit }, { payload }: { payload: string }) {
            commit("setToken", payload);
        },
    },
    // modules: {
    //     User
    // },
});
