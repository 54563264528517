import mqtt, { MqttClient } from "mqtt";
import Vue from "vue";
import router from "@/router";
import store from "@/store";
import { connectedCallback, messageCallback } from "./event"

/** mqtt服务类 */
export class mqttServer {
    private static instance: mqttServer;
    static ins (): mqttServer {
        if (!this.instance) this.instance = new mqttServer();
        return this.instance
    }
    static client?: MqttClient & { clientId?: string }
    /** vue事件总线 */
    static vueBus: Vue
    /** mqtt连接配置 */
    static config = {
        connectType: 'ws',
        api: '172.81.214.41',
        port: '8085',
        options: {
            username: '',
            password: '',
            /** 连接失败后，重连的间隔时间（毫秒） */
            reconnectPeriod: 0,
            /** 客户端向服务器发送心跳的时间间隔（秒） */
            keepalive: 0,
        }
    }
    constructor () {}
    /** 插件注入（main.ts中vue.use方法调用，会触发此install函数） */
    install (vue: typeof Vue) {
        vue.prototype.$mqtt = mqttServer.ins()
        mqttServer.vueBus = vue.prototype.$eventBus
    }
    /** mqtt连接地址 */
    get host (): string {
        if (!mqttServer.config.connectType || !mqttServer.config.api || !mqttServer.config.port) return ''
        return `${mqttServer.config.connectType}://${mqttServer.config.api}:${mqttServer.config.port}`
    }
    /** mqtt连接参数 */
    get options () {
        const token = JSON.parse(localStorage.getItem('user') || '{}').token;
        if (token) mqttServer.config.options.username = token;
        return mqttServer.config.options
    }
    get clientId () {
        return mqttServer.client?.options?.clientId || ''
    }
    /** 医院id */
    get hospitalId () {
        return Number(localStorage.getItem('hospitalId') || '0')
    }
    /** 连接mqtt服务 */
    connetServer () {
        if (!this.host) return
        try {
            if (mqttServer.client) return this.connectEnd(true)
            console.log('开始进行mqtt连接：', this.host, this.options)
            if (!this.options.username) return console.warn('mqtt链接失败：连接用户不可谓空！')
            mqttServer.client = mqtt.connect(this.host, this.options)
            if (!mqttServer.client) console.warn('创建mqtt连接失败！')
            mqttServer.client.on('connect', () => {
                    console.log('mqtt连接成功')
                    mqttServer.vueBus.$emit('mqttConnected', true)
                    if (connectedCallback) connectedCallback()
            })
            mqttServer.client.on('error', async (err: any) => {
                    console.warn('mqtt连接失败', err.message)
                    if (/Not authorized/i.test(err.message)) {
                        this.connectEnd()
                        localStorage.setItem('user', '')
                        await store.dispatch("setUserInfo", {})
                        if (router.currentRoute.name !== 'Login') {
                            router.replace({ path: '/login', query: { loginStatus: "401" } })
                        }
                    }
            })
            mqttServer.client.on('close', () => {
                console.warn('mqtt连接断开')
                mqttServer.vueBus.$emit('mqttConnected', false)
            })
            mqttServer.client.on('message', (topic: string, message: any) => {
                if (messageCallback) messageCallback(topic, message)
            })
        } catch (err) {
            console.warn('mqtt链接失败：', err)
        }
    }
    /** 断开mqtt连接 @reConnect 断开后是否需要重连 */
    connectEnd (reConnect?: boolean) {
        if (!mqttServer.client) return
        mqttServer.client.end(() => {
            mqttServer.client = undefined
            if (reConnect) this.connetServer()
        })
    }
    /** 设置mqtt连接地址（先会断开，再重新连接） */
    setConfig (config: { connectType?: string, api?: string, port?: string }) {
        mqttServer.config = { ...mqttServer.config, ...config }
        this.connetServer()
    }
    /** 订阅mqtt消息 */
    subscribe (topic: string) {
        if (mqttServer.client) mqttServer.client?.subscribe(topic)
    }
    /** 发布mqtt消息 */
    publish (topic: string, data: any) {
        console.log('消息发布：', topic, data)
        if (mqttServer.client) mqttServer.client?.publish(topic, JSON.stringify(data))
    }
}

export default mqttServer.ins();