import Vue from "vue";
import * as charts from "echarts";
import Components from "../components/index";
import store from "@/store";

import { httpCaseError, dateFormat, UniBus } from "@/utils/common";
import { Notify } from "vant";
import { NotifyOptions, VanNotify } from "vant/types/notify";
import { Fn } from "@/@type";

interface PluginObject<T> {
    install: (vue: typeof Vue, option: unknown) => void;
    [key: string]: any;
}

class BcMinx implements PluginObject<unknown> {
    install (vue: typeof Vue, options?: unknown): void {
        vue.mixin({
            data: () => ({
                storeListener: {},
                storeBackListener: {},
                initAppStoreListener: {}
            }),
            created (): void {
                Components(vue);
            },
            methods: {
                initScan (listener: Fn<void>): void {
                    // 注册事件监听
                    if (Object.prototype.toString.call(listener) === "[object Function]") {
                        this.storeListener = listener;
                        UniBus.$on('scan', listener)
                    }
                },
                initBack (listener: Fn<void>): void {
                    // 注册事件监听
                    if (Object.prototype.toString.call(listener) === "[object Function]") {
                        this.storeBackListener = listener;
                        UniBus.$on('back', listener)
                    }
                },
                bcAppInit (listener: Fn<void>): void {
                    // 注册事件监听
                    if (Object.prototype.toString.call(listener) === "[object Function]") {
                        this.initAppStoreListener = listener;
                        UniBus.$on('init', listener)
                    }
                }
            },
            destroyed () {
                let that: any = this;
                // 页面销毁时移除事件监听者
                UniBus.$off('scan', that.storeListener);
                UniBus.$off('back', that.storeBackListener);
                UniBus.$off('init', that.initAppStoreListener);
            },
        });


        const eventBus = new Vue()
        vue.prototype.$eventBus = eventBus;
        // 创建定时任务
        vue.prototype.$createTimeTask = (name: string, time: string, callback: () => void) => {
            const dateTime = new Date().getTime();
            if (new Date(time).getTime() < dateTime) return; // 时间已过期，不创建定时任务
            const timeTaskList = store.getters.timeTaskList as { name: string, time: string, callback: () => void }[];
            const index = timeTaskList.findIndex(item => item.name === name);
            if (index > -1) {
                timeTaskList[index] = { name, time, callback };
            } else {
                timeTaskList.push({ name, time, callback });
            }
            store.commit('setTimeTask', timeTaskList);
        };
        // 取消定时任务
        vue.prototype.$cancelTimeTask = (name: string) => {
            const timeTaskList = store.getters.timeTaskList as { name: string, time: string, callback: () => void }[];
            timeTaskList.splice(timeTaskList.findIndex(item => item.name === name), 1);
            store.commit('setTimeTask', timeTaskList);
        };
        vue.prototype.$httpCatchError = httpCaseError;
        vue.prototype.$charts = charts;
        (
            Date.prototype as Date & { format: (format: string) => string }
        ).format = dateFormat;

        vue.prototype.$messagePro = {
            open: function (option: NotifyOptions): VanNotify {
                this.closeAll();
                const _message = Notify(option);
                return _message
            },
            closeAll: function () {
                Notify.clear();
            }
        };
    }
}

export default new BcMinx();
