import axios from "axios";
import store from "@/store"
import router from "@/router";
import vueConfig from "@/config/index"

// 请求超时时间
axios.defaults.timeout = 10000;

// 默认携带请求头
const _defaultHeaders = axios.defaults;
_defaultHeaders.headers = {
    ...axios.defaults.headers,
};

// 请求发送拦截器
axios.interceptors.request.use(
    async (config) => {
        const token = JSON.parse(localStorage.getItem('user') || '{}').token;
        if (token) (config.headers as any)['x-access-token'] = token;
        if (vueConfig.baseURL)
            config.baseURL = vueConfig.baseURL;
        return config
    },
    (error) => Promise.reject(error)
);

// 请求响应拦截器
axios.interceptors.response.use(
    async (config: any) => {
        try {
            const refreshToken = config.headers && config.headers.refresh_token;
            if (refreshToken) {
                await store.dispatch("token", { payload: refreshToken })
            }
            return config;
        } catch (e) {
            console.log("error of interceptors response:", e);
        }
    },
    async (error) => {
        let errMsg = (error.response && error.response.data) ||
            error || { msg: "未知错误" };
        if (errMsg.message && errMsg.message.indexOf("timeout of") > -1) {
            errMsg.message = "请求超时！";
        }
        if (error.response) {
            switch (error.response.status) {
                case 401:
                case 403:
                    errMsg = { msg: "登录已经过期，请重新登录!" };
                    localStorage.setItem('user', '');
                    await store.dispatch("setUserInfo", {});
                    if (router.currentRoute.name !== 'Login') {
                        router.replace({
                            path: '/login',
                            query: {
                                loginStatus: "401"
                            },
                        }).then(r => r);
                    }
                    break;
                case 500:
                    errMsg = {
                        msg: error.response.data.msg || "服务器异常，请检查服务器状态后，重新登陆!",
                    };
                    break;
                case 404:
                    errMsg = { msg: "404：未找到服务！" };
                    break;
                default:
                    break;
            }
        }
        return error.response; // 返回接口返回的错误信息
    }
);

export function ParamMixHospital<T> (params?: T): { hospitalId: number } & T | T | { hospitalId: number } | undefined | any {
    // 当有hospitalId时，默认写入hospitalId到请求里面
    try {
        const hospitalId = Number(localStorage.getItem('hospitalId'));
        if (!params) return { hospitalId }
        return { hospitalId, ...params };
    } catch (e) {
        console.error(e);
        return params;
    }
}

export default axios;
