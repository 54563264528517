import { Fn } from "@/@type";
import Vue from "vue";
import VueRouter, { RawLocation, Route, RouteConfig } from "vue-router";
import { ErrorHandler } from "vue-router/types/router";
import store from "@/store/index";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "about" */ "../views/login/index.vue"),
    },
    {
        path: "/hospitalWrite",
        name: "HospitalWrite",
        component: () => import(/* webpackChunkName: "about" */ "../views/hospitalWrite/index.vue"),
    },
    {
      path: "/ld",
      name: "LoadPage",
      component: () => import(/* webpackChunkName: "about" */ "../views/loadPage/index.vue"),
    },
    {
        path: "/layout",
        name: 'Layout',
        meta: {
            required: true
        },
        component: () => import(/* webpackChunkName: "about" */ "../views/index.vue"),
        children: [
            {
                // 主页
                path: "/",
                name: "tabbars",
                component: () => import("../views/tabbars/index.vue"),
                children: [
                    {
                        // 主页
                        path: "/home",
                        name: "Home",
                        component: () => import("../views/home/index.vue"),
                    },
                    {
                        // 患者信息
                        path: "/patientinfo",
                        name: "PatientInfo",
                        component: () => import("../views/patientInfo/index.vue"),
                    },
                    {
                        // 菜单栏
                        path: "/menu",
                        name: "Menu",
                        component: () => import("../views/menu/index.vue"),
                    },
                    {
                        path: "/",
                        redirect: '/home'
                    }

                ]
            },
            {
                // 投诉建议
                path: "/consultingcomplaints",
                name: "ConsultingComplaints",
                component: () => import("../views/consultingComplaints/index.vue"),
            },
            {
                // 智能助手
                path: "/intelligent",
                name: "Intelligent",
                component: () => import("../views/intelligent/index.vue"),
            },
            {
                // 智能助手
                path: "/qd",
                name: "QueueDetails",
                component: () => import("../views/queueDetails/index.vue"),
            },
            {
                // 数据监测
                path: "/datawatch",
                name: "DataWatch",
                component: () => import("../views/dataWatch/index.vue"),
            },
            {
                // 消息通知
                path: "/message",
                name: "Message",
                component: () => import("../views/message/index.vue"),
            },
            {
                // 报到申请
                path: "/reportapplication",
                name: "ReportApplication",
                component: () => import("../views/reportApplication/index.vue"),
            },
            {
                // 调查问卷和健康宣教的列表展示
                path: "/itemlist/:pageType",
                name: "ItemList",
                component: () => import("../views/itemList/index.vue"),
                meta: {
                    isKeep: false
                },

            },
            {
                // 健康宣教详细
                path: "/acticledetails",
                name: "ActicleDetails",
                component: () => import("../views/itemList/acticleDetails/index.vue"),
                meta: {
                    isKeep: false
                },
            },
            {
                // 调查问卷详细
                path: "/questiondetails",
                name: "QuestionDetails",
                component: () => import("../views/itemList/questionDetails/index.vue"),
                meta: {
                    isKeep: false
                },
            },
            {
                // 绑定就诊卡
                path: "/bindpatient",
                name: "BindPatient",
                component: () => import("../views/bindPatient/index.vue"),
            },
            {
                // 个人信息
                path: "/information",
                name: "Information",
                component: () => import("../views/information/index.vue"),
            },
            {
                // 病史记录
                path: "/medicalhistory",
                name: "MedicalHistory",
                component: () => import("../views/information/medicalHistory/index.vue"),
            },
            {
                // 病史详细
                path: "/medicalhistorydetails",
                name: "MedicalHistoryDetails",
                component: () => import("../views/information/medicalHistory/details/index.vue"),
                meta: {
                    isKeep: false
                },
            },
            {
                // 随访详情
                path: "/visitdetail",
                name: "VisitDetail",
                component: () => import("../views/home/visitDetail/index.vue"),
                meta: {
                    isKeep: false
                },
            },
            {
                // 服务医生
                path: "/doctor",
                name: "Doctor",
                component: () => import("../views/home/doctor/index.vue"),
            },
            {
                // 随访历史
                path: "/visitrecord",
                name: "VisitRecord",
                component: () => import("../views/information/visitRecord/index.vue"),
            },
            {
                // 随访历次反馈
                path: "/feedback",
                name: "Feedback",
                component: () => import("../views/feelback/index.vue"),
                meta: {
                    isKeep: false
                },
            },
            {
                // 随访历次反馈详情
                path: "/feelbackdetail",
                name: "FeelbackDetail",
                component: () => import("../views/feelback/detail/index.vue"),
                meta: {
                    isKeep: false
                },
            },
            {
                // 用药提醒
                path: "/medicineRemind",
                name: "MedicineRemind",
                component: () => import("../views/medicineRemind/index.vue"),
                meta: {
                    isKeep: false
                },
            },
            {
                // 体征录入
                path: "/entrySign",
                name: "EntrySign",
                component: () => import("../views/home/entrySign/index.vue"),
                meta: {
                    isKeep: false
                },
            }
        ]
    },
    {
        path: "/",
        redirect: '/layout'
    },
    // {
    //     path: "*",
    //     redirect: '/login'
    // }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

const originPush: any = VueRouter.prototype.push;
// push被赋予刷新属性
VueRouter.prototype.push = function (to: RawLocation, onComplete?: any, onAbort?: any) {
    if (typeof to !== 'string') {
        to.params = { ...to.params, isRefresh: 'true' };
    }
    return originPush.call(this, to, onComplete, onAbort);
}

const originReplace: any = VueRouter.prototype.replace;
// replace被赋予刷新属性
VueRouter.prototype.replace = function (to: RawLocation, onComplete?: any, onAbort?: any) {
    if (typeof to !== 'string') {
        to.params = { ...to.params, isRefresh: 'true' };
    }
    return originReplace.call(this, to, onComplete, onAbort);
}

const HISTORYS: string[] = [];
router.beforeEach((to: Route, from: Route, next: Fn<void>) => {
    // 回退缓存机制
    let index = HISTORYS.indexOf(to.path);
    if (index === -1) {
        if (to.path === '/home') {
            HISTORYS.splice(0, HISTORYS.length)
            HISTORYS.push('/home');
            store.commit('setExcludePage', from.name);
            // 用于保证数据不会进入同一次检查,还原配置
            setTimeout(() => store.commit('removalExcludePage'), 1);
        } else {
            HISTORYS.push(to.path);
        }
        next();
        return;
    }
    if (index !== HISTORYS.length - 2 || to.params.isRefresh === 'true') {
        store.commit('switchIsRefresh');
        HISTORYS.splice(0, HISTORYS.length)
        HISTORYS.push(to.path);
    } else {
        HISTORYS.splice(index + 1, 1);
        store.commit('setExcludePage', from.name);
        // 用于保证数据不会进入同一次检查,还原配置
        setTimeout(() => store.commit('removalExcludePage'), 1)
    }
    next();
});

export default router;
