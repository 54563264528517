import { CanvasOption, UniComm } from "@/@type/index";
import router from '@/router/index'
import Vue from 'vue';


// 创建一个关于uinapp消息的总线
export const UniBus = new Vue();
/* 工具类封装函数 */

/* 请求捕获异常函数 */
export const httpCaseError = async (callback: any) => {
    try {
        const result: any = await callback();
        if (result?.status === 200 && result?.statusText === "OK") {
            return [result.data];
        }
        console.error("http catch error in:", result);
        return [result, true];
    } catch (e) {
        return [null, e];
    }
};

/* 日期格式化插件 */
export function dateFormat (this: Date, format: string): string {
    const date: any = {
        "M+": this.getMonth() + 1,
        "d+": this.getDate(),
        "h+": this.getHours(),
        "m+": this.getMinutes(),
        "s+": this.getSeconds(),
        "q+": Math.floor((this.getMonth() + 3) / 3),
        "S+": this.getMilliseconds(),
    };
    if (/(y+)/i.test(format)) {
        format = format.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    }
    Object.keys(date).forEach((k: string) => {
        if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(
                RegExp.$1,
                RegExp.$1.length === 1
                    ? date[k]
                    : ("00" + date[k]).substr(("" + date[k]).length)
            );
        }
    });
    return format;
}

/* 请求FormData格式化 */
export function fileFormData (value: unknown): unknown {
    const formData = new FormData();
    if (value instanceof Object) {
        for (const i of Object.keys(value)) {
            formData.append(i, (value as never)[i])
        }
    } else {
        console.error("类型转换异常，传递格式并非指定格式!")
    }
    return formData
}


// 绘制canvas
export const draw = (option: CanvasOption, contentText: HTMLElement) => {
    const canvas = contentText as HTMLCanvasElement, ctx = canvas.getContext('2d') as any;

    const { lineList, style, type } = option;
    if (!lineList || !type) return console.log("canvas绘制异常...");

    if (!ctx) {
        return;
    }
    Object.keys(style).some(item => {
        ctx[item] = style[item]
    })

    lineList.some(item => {
        ctx.beginPath();

        item.length && ctx.moveTo(item[0][0], item[0][1]);
        item.some((item_s: any, index: any) => {
            if (index) {
                ctx.lineTo(item_s[0], item_s[1])
            }
        })
        ctx.closePath();
        if (type === 'line') ctx.stroke();
    })
    return ctx
}

// 时间转换
export const formatTime = (date: Date | string | number, type: string) => {
    if (!date) return ''
    const nowDate = new Date(date)
    const obj: any = {
        'YYYY': nowDate.getFullYear(),
        'MM': nowDate.getMonth() > 8 ? (nowDate.getMonth() + 1) : '0' + (nowDate.getMonth() + 1),
        'DD': nowDate.getDate() > 9 ? nowDate.getDate() : '0' + nowDate.getDate(),
        'hh': nowDate.getHours() > 9 ? nowDate.getHours() : '0' + nowDate.getHours(),
        'mm': nowDate.getMinutes() > 9 ? nowDate.getMinutes() : '0' + nowDate.getMinutes(),
        'ss': nowDate.getSeconds() > 9 ? nowDate.getSeconds() : '0' + nowDate.getSeconds()
    }
    let str = type ? type : 'YYYY/MM/DD hh:mm:ss'
    for (const key in obj) {
        str = str.replace(key, obj[key])
    }
    return str
}

export const downloadFile = (filePath: string, fileName: string) => {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(filePath));
    if (fileName) element.setAttribute('download', fileName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

/**
 * 接收app端的消息
 * @description uniApp通过webView进行通信
 */
(window as any)['uniComm'] = function (value: UniComm) {
    switch (value.type) {
        case 'scan':
        case 'back': ;
        case 'init':
            UniBus.$emit(value.type, value.data);
            break;
        case 'authority':
            window.uni.isAuthority = value.data;
            break;
    }
}