<template>
    <div id="app">
        <van-overlay :show="pageLoading" class="one-line-center" z-index="99">
            <div class="flex-1"></div>
            <van-loading size="24px" vertical>{{ pageLoadingText }}</van-loading>
            <div class="flex-1"></div>
        </van-overlay>
        <router-view />
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";
import { getHospital } from "@/api/index";
import { patientLogin, getPatientList } from "@/api/index";
import { watch } from "vue";

@Component({
    components: {},
    name: 'App'
})

export default class App extends Vue {
    @Getter timeTaskList!: { name: string, time: string, callback: () => void }[]
    @Mutation setHospitalList!: Function
    @Mutation readonly setUserInfo!: Function;
    @Mutation readonly setToken!: Function;

    @Watch('$route')
    routeChange () {
        const backDisabled = !/^\/home$|^\/ld$|^\/login$|^\/$/i.test(this.$route.path)
        window.uni?.postMessage<boolean>({
            data: { type: 'backDisabled', msg: backDisabled }
        });
    }

    async created () {
        // 监听app消息
        this.initBack(this.appBack.bind(this))
        this.bcAppInit(this.appInit.bind(this))
        this.initTimeTask()
        await this.defaultLogin()
        this.getHospitalList()
        this.openApp()
    }
    mounted () {
        setTimeout(() => {
            this.routeChange()
            window.uni.postMessage({
                data: {
                    type: 'webInit',
                }
            })
        }, 1000)
    }

    pageLoading = false;
    pageLoadingText = '加载中...';
    timeTask: any = null;
    /** 需要监听的app/vue消息 */
    busList: { type: 'app' | 'vue', eventName: string, callback: Function }[] = [
        // { type: 'app', eventName: 'back', callback: this.appBack },
        // { type: 'app', eventName: 'init', callback: this.appInit },
        { type: 'vue', eventName: 'appLoading', callback: this.appLoading },
    ]

    /** 初始化定时任务 */
    initTimeTask () {
        if (this.timeTask) {
            clearTimeout(this.timeTask);
            this.timeTask = null;
        }
        this.timeTask = setTimeout(() => {
            const dateTime = new Date().getTime()
            this.timeTaskList.forEach((item: any) => {
                const diff = dateTime - new Date(item.time).getTime()
                // 定时执行（允许2秒误差）
                if (diff >= 0 && diff <= 2000 && typeof item.callback === 'function') {
                    item.callback()
                    this.$cancelTimeTask(item.name)
                }
            })
            this.initTimeTask();
        }, 1000);
    }
    /** app全局loading */
    appLoading (loading: boolean) {
        this.pageLoading = loading
        this.pageLoadingText = '加载中...'
    }
    /** 获取医院列表 */
    getHospitalList () {
        getHospital().then(res => {
            this.setHospitalList(res.data?.result || [])
        }).catch(err => { })
    }
    /** 默认登录 */
    async defaultLogin (urlParams?: any) {
        if (!urlParams)
            urlParams = this.getUrlParams()
        if (!urlParams.hospitalId || !urlParams.patientId) return
        this.pageLoading = true
        try {
            const loginRes = await patientLogin(Number(urlParams.hospitalId))
            const token = loginRes?.data?.result?.token || ''
            if (!token) throw '未获取到token'
            localStorage.setItem('hospitalId', urlParams.hospitalId)
            this.setToken(token)
            let patientRes = await getPatientList(urlParams.patientId);
            if (!patientRes?.data?.result || patientRes?.data?.result.length === 0) throw '未获取到就诊卡信息'
            const userInfo = patientRes.data.result[0] || {}
            this.setUserInfo(userInfo)
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
            const user = { username: userInfo.patientId, hospitalId: urlParams.hospitalId, token }
            localStorage.setItem('user', JSON.stringify(user));
            this.$eventBus.$emit('loggedIn')
            this.pageLoading = false
        } catch (err) {
            this.pageLoading = false
            console.warn('默认登录失败：', err)
        }
    }
    /** 唤起app */
    openApp () {
        const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
        if (userInfo.patientId && !/uni-app/i.test(navigator.userAgent)) { // 非app环境时，唤起app
            const href = (window.location.href.split("?")[0] || '').split('//')[1] || ''
            const index = href.indexOf('/')
            let data = {
                patientId: userInfo.patientId,
                hospitalId: localStorage.getItem('hospitalId') || '',
                path: index > -1 ? href.substring(index) : ''
            }
            window.location.href = `bcfvs://${JSON.stringify(data)}`;
        }
    }
    /** app被唤起时 */
    appInit (data: any) {
        // if (!data) return
        // try {
        //     const params = JSON.parse(data)
        //     this.defaultLogin(params)
        //     if (params.path && params.path !== '/') {
        //         this.$router.push({ path: params.path, query: { patientId: params.patientId, hospitalId: params.hospitalId } })
        //     }
        // } catch (err) {
        //     console.warn('app唤起时，逻辑出错：', err)
        // }
    }
    /** 获取url上的参数 */
    getUrlParams () {
        const params: any = {}
        const url = decodeURIComponent(window.location.href) || '';
        (url.split('?')[1] || '').split('&').forEach(item => {
            const [key, value] = item.split('=');
            params[key] = value
        })
        return params
    }
    /** 回退 */
    appBack () {
        this.$router.back()
    }
}
</script>

<style lang="less">
@import "./styles/global.less";
@import "./styles/_variables.less";
@import "./styles/iconfontWeappIcon.less";
@import "./styles/iconfont/iconfont.css";
</style>
