import mqtt, { mqttServer } from "./index"

/** 所有订阅列表 */
let subscribeList: any[] = []
const initSubscribeList = () => {
    const hospitalId = mqtt.hospitalId || ''
    const patientId = JSON.parse(localStorage.getItem('userInfo') || '{}').patientId || ''
    subscribeList = [
        { topic: `bc/fvs/server/patient/queue/${mqtt.clientId || ''}`, eventName: 'patientQueueList', title: '获取到患者排队信息' },
        { topic: `bc/fvs/web/patient/queue/${hospitalId}`, eventName: 'patientQueueList', title: '患者排队信息变更' },
        { topic: `bc/fvs/web/patient/status/${hospitalId}/${patientId}`, eventName: 'patientCheckChange', title: '患者检查状态变更' }
    ]
}

export const publishList = {
    /** 获取患者排队信息 */
    getPatientQueueList: () => {
        mqtt.publish('bc/fvs/server/patient/queue', {
            hospitalId: mqtt.hospitalId
        })
    }
}

/** mqtt连接后的回调函数 */
export const connectedCallback = () => {
    initSubscribeList()
    // 订阅所有主题
    subscribeList.forEach(item => {
        mqtt.subscribe(item.topic)
    })
    publishList.getPatientQueueList()
}

/** mqtt收到消息后的回调函数 */
export const messageCallback = (topic: string, message: any) => {
    subscribeList.forEach((item: any) => {
        if (item.topic === topic) {
            console.log('收到消息：', item.title, topic, JSON.parse(message))
            mqttServer.vueBus.$emit(item.eventName, JSON.parse(message))
        }
    })
}
