/*
*  请求接口列表
* */
export default {
    USER_LIST: '/api/user',
    USER_UNCHECKING: '/api/user/unchecking',
    USER_LOGIN: '/api/user/login',
    USER_LOGIN_PATIENT: '/api/app/wechat/minprogram/openid',
    // 获取患者列表
    PATIENT_CARD_LIST: '/api/app/wechat/minprogram/patient',
    ADD_USER: '/api/user',
    // 用户绑定医生
    BIND_DOCTOR: '/api/user/bind/doctor',
    // 用户与医生解除绑定
    UNBIND_DOCTOR: "/api/user/unbind/doctor",
    // 随访记录（随访历史）
    FOLLOW_RECORD: '/api/follow/info/record',
    // 获取患者随访信息
    FOLLOW_INFO: "/api/follow/info",
    // 获取HIS数据中的患者详情
    PATIENT_DETAIL: "/api/his/inpatient/info",
    // 患者列表（随访系统）
    PATIENT_LIST: "/api/patient/info",
    // 患者姓名
    PATIENT_UNSAFE: '/api/patient/info/unsafe',
    // 医患沟通列表
    PATIENT_COMMUNICATION: "/api/communication/record/patient",
    // 医患沟通患者详情
    DP_COMMUNICATION_DETAIL: "/api/communication/record",
    // 医生名下管理的患者列表
    DOCTOR_PATIENT: "/api/doctor/info/patient",
    // 获取宣教详情列表
    PATIENT_EDU_ARTICLE: "/api/teach/article",
    // 患者随访计划
    FOLLOW_PLAN: "/api/follow/patient/schedule/",
    // 检查患者是否有未完成的随访计划
    FOLLOW_PLAN_CHECK_UNSAFE: '/api/follow/patient/schedule/check/unsafe',
    // 随访任务
    PATIENT_TASK: "/api/follow/task",
    // 投诉与建议
    COMPLAINTS_SUGGEST: "/api/complaint/suggest",
    // 登陆日志
    LOGIN_LOG: "/api/user/log",
    // 科室列表
    DEPTH_LIST: "/api/his/dict/dept",
    // 病区列表
    WARD_LIST: "/api/his/dict/ward",
    //患者类型列表
    PATIENT_TYPE_LIST: "/api/follow/patient/label/dict",
    // 获取患者随访计划模板
    PATIENT_FOLLOW_TEMPLATE: "/api/follow/schedule/template",
    //获取宣教文章类型
    ARTICLE_TYPE: "/api/teach/article/type",
    // 获取宣教文章类型下的文章
    ARTICLE_DETAIL: "/api/teach/article",
    // 获取调查表模板
    INVESTIGATION_TYPE: "/api/survey/temp",
    // 获取调查表详情
    INVESTIGATION_DETAIL: "/api/survey/info",
    // 获取调查表结果详情
    INVESTIGATION_DETAIL_DONE: "/api/survey/result",
    // 获取检查单字典数据（his）
    CHECK_TEMPLATE: "/api/his/dict/exam",
    // 获取检验单字典数据（his）
    INSPECTION_TEMPLATE: "/api/his/dict/lab",
    // 检查单字典数据（随访系统）
    CHECK_TEMP_LIST: "/api/check/bcsf/exam",
    // 检验单字典数据（随访系统）
    CHECKOUT_TEMP_LIST: "/api/check/bcsf/lab",
    // 检验明细字典数据（随访系统）
    CHECKOUT_ITEM_DICT: "/api/check/bcsf/lab/item",
    // 新增患者检验项目
    PROJECT: "/api/follow/project",
    // 新增随访项目明细
    PROJECT_ITEM: "/api/follow/project/item",
    // 检查单检验单图片上传
    PROJECT_ITEM_IMAGE: "/api/check/image",
    // 获取血糖记录接口
    BLOOD_SUGAR: "/api/data/record/blood/glucose",
    // 获取血压记录接口
    BLOOD_PRESSURE: "/api/data/record/blood/pressure",
    // 获取服药记录接口
    TAKE_MEDICINE: "/api/data/record/take/medicine",
    // 获取体温记录接口
    TEMPERATURE: "/api/data/record/temperature",
    // 获取体重记录接口
    WEIGHT: "/api/data/record/weight",
    // 获取调查问卷详情接口
    SURVEY_DETAIL: "/api/survey/info/patient",
    // 用药提醒接口
    MEDICINE_MIND: "/api/medicine/mind",
    // 医生列表接口
    DOCTOR_LIST: "api/doctor/info",
    // 患者报道申请接口
    FOLLOW_REPORT: "/api/follow/report",
    // 药品字典接口
    DRUG_DICT: "/api/medicine/drug",
    // 患者医嘱药单
    PATIENT_ORDER_DRUG: "/api/patient/order",
    // 患者入院诊断信息（病史信息）
    PATIENT_DIAG: "/api/his/patient/diag",
    // 患者医嘱信息
    PATIENT_ORDER: "/api/his/patient/order",
    // 每个科室的随访计划铜价
    DEPT_FOLLOW_PLAN_STAT: "/api/stat/followup/task/dept",
    // 医生随访计划/任务统计
    DOCTOR_FOLLOW_STAT: "/api/stat/followup/schedule/task/count",
    // 患者入院统计数据
    PATIENT_FOLLOW_STAT: "/api/stat/followup/count/rate",
    // 患者模板下载
    PATIENT_EXCEL_EXPORT: "/api/patient/info/export/excel",
    // 患者模板导入
    PATIENT_EXCEL_IMPORT: "/api/patient/info/import/excel",
    // 角色字典管理
    ROLE_LIST: "/api/rbac/role",
    // 今日待随访列表
    TODAY_FOLLOW: "/api/stat/followup/stay",
    // 医生头像上传
    DOCTOR_PROFILE_PHOTO: "/api/doctor/info/upload",
    // 患者头像上传
    PATIENT_PROFILE_PHOTO: "/api/patient/img/upload",
    // 权限管理（角色-用户绑定）
    ROLE_USER: "/api/rbac/role/user",
    // 宣教文章富文本上传图片
    ARTICLE_IMG_UPLOAD: "/api/survey/img/upload",
    // 宣教文章富文本上传视频
    ARTICLE_VIDEO_UPLOAD: "/api/survey/video/upload",
    // 短信模板接口
    SMS_TEMP: "/api/sms/template",
    // 获取所有短信参数
    SMS_PARAMS: "/api/sms/param/all",
    // 短信类别接口
    SMS_TYPE: "/api/sms/type",
    // 发送短信
    SEND_SMS: "/api/sms/send",
    // 发送短信验证码
    SEND_PHONE_CODE: "/api/phone/code",
    // 校验验证码是否正确
    VALIDATE_CODE: "/api/phone/code/validation",
    // 短信模板-类别绑定
    SMS_TEMP_TYPE_BIND: "/api/sms/template/type",
    // 新增短信模板-类别绑定
    ADD_SMS_TEMP_TYPE: "api/sms/template/type/edit",
    // 药品使用说明接口
    DRUG_INSTRUCTION: "/api/medicine/drug/instruction",
    // 患者信息同步接口
    PATIENT_SYNC: "/api/cron/data/equal/patient/info",
    // 医生信息同步接口
    DOCTOR_SYNC: "/api/cron/data/equal/doctor",
    // 检验字典信息同步接口
    CHECK_OUT_SYNC: "/api/cron/data/equal/lab",
    // 检验字典明细信息同步接口
    CHECK_OUT_ITEM_SYNC: "/api/cron/data/equal/lab/item",
    // 检查信息同步接口
    CHECK_UP_SYNC: "/api/cron/data/equal/exam",
    // 药品信息同步接口
    DRUG_SYNC: "/api/cron/data/equal/drug",
    // 药物剂量单位字典信息同步接口
    DRUG_DOSE_SYNC: "/api/cron/data/equal/dosage/unit",
    // 药品说明信息同步接口F
    DRUG_INSTRUCTION_SYNC: "/api/cron/data/equal/drug/instruction",
    // 获取某个患者所有短信参数值
    PATIENT_PARAM_VALUE: "/api/sms/param",
    // 患者定时消息任务接口(短信定时任务/微信消息定时任务)
    PATIENT_SMS_TASK: "/api/sms/task",
    // 系统消息
    SYS_MSG: "/api/tips/system",
    // 随访病种
    FOLLOW_DISEASE: "/api/follow/disease/dict",
    // 患者绑定病种信息列表
    PATIENT_DISEASE: "/api/follow/patient/disease",
    // 短信模板绑定病种信息列表
    SMS_DISEASE: "/api/sms/template/disease",
    // 上传药物图片
    UPLOAD_DRUG_IMG: "/api/medicine/drug/img/upload",
    // 报到二维码表格接口（待定，暂用此接口模拟）
    QR_CODE_LIST: "/api/sms/template/disease",
    // 生成小程序二维码
    GET_QR_CODE: "/api/sms/qrcode",
    // 获取小程序access_token
    GET_ACCESS_TOKEN: "/api/app/wechat/minprogram/token",
    // 随访患者检验信息
    CHECK_OUT_INFO: "/api/check/out",
    // 医生
    DOCTOR_INFO_LIST: ' /api/doctor/info',
    // 患者报到接口（新）
    REPORT_RECEIVE: '/api/follow/report/v2/receive',
    // 获取医生的各项数据统计
    DOCTOR_COUNT: '/api/stat/followup/doctor/count',
    // 随访任务
    PATIENT_CHECK: '/api/app/endoscope/patient/check',
    // 医院字典接口
    HOSPITAL_DICT: "/api/hospital",
    // 退出登录
    LOGOUT: "/api/user/logout",
    // 科室
    DEPT: '/api/dict/dept',
    // 患者随访信息（术前/术后）
    FOLLOW_STATUS: '/api/patient/info/simple/data',
    /** 患者术前随访记录 */
    PATIENT_PEDT_RECORD: '/api/pedt/record',
    /** 患者术前随访记录详细 */
    PATIENT_PEDT_RECORD_DETAIL: '/api/pedt/record/detail',
    /** 上传术前随访服药图片 */
    UPLOAD_PEDT_IMG: '/api/pedt/record/drug/use/img',
}