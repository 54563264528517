import Vue from "vue";
import Layout from "./layout/blankLayout/index.vue";
import router from "./router";
import store from "./store";
import App from "./App.vue";

import vant from "vant";
import "vant/lib/index.css";
import Plugins from "@/plugin/index";
import mqttServer from "@/mqtt"

Vue.use(vant);
Vue.use(Plugins);
Vue.use(mqttServer);
Vue.config.productionTip = false;

// 解决ios浏览器缩放问题
window.onload = function () {
    // 禁用双指放大
    document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
            event.preventDefault()
        }
    });
    document.addEventListener('gesturestart', function (event) {
        event.preventDefault()
    })
    // 禁用双击放大
    var lastTouchEnd = 0;
    document.documentElement.addEventListener('touchend', function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
            event.preventDefault();
        }
        lastTouchEnd = now;
    }, {
        passive: false
    });
}

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
