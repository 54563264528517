<template>
    <svg class="font-icon" aria-hidden="true" :style="{ 'fontSize': size }">
        <use :xlink:href="'#' + icon" />
    </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    name: "Icon",
    components: {},
})
export default class Icon extends Vue {
    @Prop({ default: '' }) icon!: string;
    @Prop({ default: '1rem' }) size !: string;
}
</script>

<style lang="less">
.font-icon {
    width: 1em;
    height: 1em;
    overflow: hidden;
    fill: currentColor;
    vertical-align: middle;
}
</style>